<template>
  <div>
    <v-card class="pa-4">
      <p class="text-2xl ma-5">Créer / Éditer un PartnerAccount</p>
      <!-- <p class="mx-6 mb-5">Sub line</p> -->
      <v-row class="mx-1 my-3">
        <!-- > -->
        <v-card-text>
          <v-form ref="FormPartnerAccount">
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="id"
                  label="Id"
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-select
                  outlined
                  dense
                  v-model="department_id"
                  :items="departmentList"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Département"
                  item-text="name"
                  item-value="id"
                  class="select-department shrink"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12">
                <v-select
                  outlined
                  dense
                  :items="partners"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Partner"
                  item-text="name"
                  item-value="code"
                  class="select-partner shrink"
                  v-model="selectedPartnerCode"
                  @input="setSelectedPartnerCode"
                ></v-select>

                <v-text-field
                  v-model="partner_id"
                  label="Partner Id"
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="name"
                  label="Name"
                  outlined
                  dense
                  required
                ></v-text-field>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Example: Lorem ipsum
                </p>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="code"
                  label="Code"
                  outlined
                  dense
                  required
                ></v-text-field>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Example: loremipsum
                </p>
              </v-col>

              <v-col v-if="'identifier' in fieldsToDisplay" cols="12" sm="12">
                <v-text-field
                  v-model="identifier"
                  :label="fieldsToDisplay.identifier"
                  outlined
                  dense
                ></v-text-field>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Example: 1a2z3e4r5t6y7u8i9o
                </p>
              </v-col>

              <v-col v-if="'login' in fieldsToDisplay" cols="12" sm="12">
                <v-text-field
                  v-model="login"
                  :label="fieldsToDisplay.login"
                  outlined
                  dense
                ></v-text-field>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Example: BGdu93
                </p>
              </v-col>

              <v-col v-if="'email' in fieldsToDisplay" cols="12" sm="12">
                <v-text-field
                  v-model="email"
                  :label="fieldsToDisplay.email"
                  outlined
                  dense
                ></v-text-field>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Example: bgdu93@gmail.com
                </p>
              </v-col>

              <v-col v-if="'password' in fieldsToDisplay" cols="12" sm="12">
                <v-text-field
                  v-model="password"
                  :label="fieldsToDisplay.password"
                  outlined
                  dense
                ></v-text-field>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Example: Lorem_ipsum_blabla
                </p>
              </v-col>

              <v-col v-if="'client_id' in fieldsToDisplay" cols="12" sm="12">
                <v-text-field
                  v-model="client_id"
                  :label="fieldsToDisplay.client_id"
                  outlined
                  dense
                ></v-text-field>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Example: Lorem_ipsum_blabla
                </p>
              </v-col>

              <v-col
                v-if="'client_secret' in fieldsToDisplay"
                cols="12"
                sm="12"
              >
                <v-textarea
                  v-model="client_secret"
                  :label="fieldsToDisplay.client_secret"
                  outlined
                  dense
                  class="field_client_secret"
                ></v-textarea>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Example: Lorem_ipsum_blabla
                </p>
              </v-col>

              <v-col v-if="'app_id' in fieldsToDisplay" cols="12" sm="12">
                <v-text-field
                  v-model="app_id"
                  :label="fieldsToDisplay.app_id"
                  outlined
                  dense
                ></v-text-field>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Example: Lorem_ipsum_blabla
                </p>
              </v-col>

              <v-col v-if="'app_secret' in fieldsToDisplay" cols="12" sm="12">
                <v-textarea
                  v-model="app_secret"
                  :label="fieldsToDisplay.app_secret"
                  outlined
                  dense
                  class="field_app_secret"
                ></v-textarea>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Example: Lorem_ipsum_blabla
                </p>
              </v-col>

              <v-col v-if="'api_key' in fieldsToDisplay" cols="12" sm="12">
                <v-textarea
                  v-model="api_key"
                  :label="fieldsToDisplay.api_key"
                  outlined
                  dense
                  class="field_api_key"
                ></v-textarea>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Example: Lorem_ipsum_blabla
                </p>
              </v-col>

              <v-col v-if="'api_secret' in fieldsToDisplay" cols="12" sm="12">
                <v-textarea
                  v-model="api_secret"
                  :label="fieldsToDisplay.api_secret"
                  outlined
                  dense
                  class="field_api_secret"
                ></v-textarea>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Example: Lorem_ipsum_blabla
                </p>
              </v-col>

              <v-col
                v-if="'key_file_text' in fieldsToDisplay"
                cols="12"
                sm="12"
              >
                <v-textarea
                  v-model="key_file_text"
                  :label="fieldsToDisplay.key_file_text"
                  outlined
                  dense
                  class="field_key_file_text"
                ></v-textarea>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Example: json content of a authenticated_credential.json
                </p>
              </v-col>

              <v-col
                v-if="'key_file_text_2' in fieldsToDisplay"
                cols="12"
                sm="12"
              >
                <v-textarea
                  v-model="key_file_text_2"
                  :label="fieldsToDisplay.key_file_text_2"
                  outlined
                  dense
                  class="field_key_file_text_2"
                ></v-textarea>
                <v-row class="mt-n4 font-weight-thin text-lg-left">
                  <v-col cols="5"></v-col>
                  <v-col cols="7">
                    <p v-show="selectedPartnerCode == 'gam'">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="showConfigExample = !showConfigExample"
                        >Afficher un exemple de config</a
                      >
                    </p>

                    <div v-show="showConfigExample">
                      <div>
                        Attention: seules ces formats de dates sont possibles
                        :<br />
                        <strong>"Mmm d, yyyy - Mmm d, yyyy"</strong> pour par
                        exemple: "Oct 8, 2022 - Oct 8, 2022"<br />
                        <strong>"DD-Mmm-yyyy - DD-Mmm-yyyy"</strong> pour par
                        exemple: "08-Oct-2022 - 08-Oct-2022"<br />
                        Pour en avoir d'autre, demander à l'administrateur.
                      </div>

                      <pre class="text-body-2 font-weight-thin">
[
  {
    "source_type": "video_prebid",
    "report_id": "14426999854",
    "url": "https://admanager.google.com/46980923#reports/report/detail/report_id=14426999854",
    "file_name_pattern": "RMR CA VIDEO_PREBID BY PUBSTACK_ReworldMedia (Mmm d, YYYY - Mmm d, YYYY).csv",
    "revenue_col": "Ad server CPM and CPC revenue (€)",
    "impressions_col": "Ad server impressions",
    "is_active": 1
  },
  {
    "source_type": "video_amazon_gam",
    "report_id": "14430031795",
    "url": "https://admanager.google.com/46980923#reports/report/detail/report_id=14430031795",
    "file_name_pattern": "RMR CA VIDEO_PREBID BY AMAZON_ReworldMedia (Mmm d, YYYY - Mmm d, YYYY).csv",
    "revenue_col": "Ad server CPM and CPC revenue (€)",
    "impressions_col": "Ad server impressions",
    "is_active": 1
  },
  {
    "source_type": "video_ob",
    "report_id": "14427040334",
    "url": "https://admanager.google.com/46980923#reports/report/detail/report_id=14427040334",
    "file_name_pattern": "RMR CA VIDEO_GOOGLE OPEN BIDDING_ReworldMedia (Mmm d, YYYY - Mmm d, YYYY).csv",
    "revenue_col": "Ad server CPM and CPC revenue (€)",
    "impressions_col": "Ad server impressions",
    "is_active": 1
  },
  {
    "source_type": "video_adx",
    "report_id": "14427006843",
    "url": "https://admanager.google.com/46980923#reports/report/detail/report_id=14427006843",
    "file_name_pattern": "RMR CA VIDEO_GOOGLE ADX_ReworldMedia (Mmm d, YYYY - Mmm d, YYYY).csv",
    "revenue_col": "Ad server CPM and CPC revenue (€)",
    "impressions_col": "Ad server impressions",
    "is_active": 1
  },
  {
    "source_type": "display_adx",
    "report_id": "14430132222",
    "url": "https://admanager.google.com/46980923#reports/report/detail/report_id=14430132222",
    "file_name_pattern": "RMR CA DISPLAY_GOOGLE ADX_ReworldMedia (Mmm d, YYYY - Mmm d, YYYY).csv",
    "revenue_col": "Ad server CPM and CPC revenue (€)",
    "impressions_col": "Ad server impressions",
    "is_active": 1
  },
  {
    "source_type": "display_ob",
    "report_id": "14430135673",
    "url": "https://admanager.google.com/46980923#reports/report/detail/report_id=14430135673",
    "file_name_pattern": "RMR CA DISPLAY_GOOGLE OPEN BIDDING_ReworldMedia (Mmm d, YYYY - Mmm d, YYYY).csv",
    "revenue_col": "Ad server CPM and CPC revenue (€)",
    "impressions_col": "Ad server impressions",
    "is_active": 1
  },
  {
    "source_type": "display_prebid",
    "report_id": "12592565112",
    "url": "https://admanager.google.com/46980923#reports/report/detail/report_id=12592565112",
    "file_name_pattern": "RMR CA DISPLAY_PREBID_ReworldMedia (Mmm d, YYYY - Mmm d, YYYY).csv",
    "revenue_col": "Ad server CPM and CPC revenue (€)",
    "impressions_col": "Ad server impressions",
    "is_active": 1
  },
  {
    "source_type": "display_prebid",
    "report_id": "14430163901",
    "url": "https://admanager.google.com/46980923#reports/report/detail/report_id=14430163901",
    "file_name_pattern": "RMR CA DISPLAY_PREBID BY PUBSTACK_ReworldMedia (Mmm d, YYYY - Mmm d, YYYY).csv",
    "revenue_col": "Ad server CPM and CPC revenue (€)",
    "impressions_col": "Ad server impressions",
    "is_active": 1
  },
  {
    "source_type": "display_prebid_amazon",
    "report_id": "14442700904",
    "url": "https://admanager.google.com/46980923#reports/report/detail/report_id=14442700904",
    "file_name_pattern": "RMR CA DISPLAY_PREBID_Amazon_ReworldMedia (Mmm d, YYYY - Mmm d, YYYY).csv",
    "revenue_col": "Ad server CPM and CPC revenue (€)",
    "impressions_col": "Ad server impressions",
    "is_active": 1
  }
]
</pre
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                v-if="'key_file_binary' in fieldsToDisplay"
                cols="12"
                sm="12"
              >
                <!--v-model="key_file_binary"-->
                <v-file-input
                  :key="inputFileFieldsComponentKeys.key_file_binary"
                  show-size
                  @change="onChangeFileUploadKeyFileBinay"
                  :label="fieldsToDisplay.key_file_binary"
                  outlined
                  dense
                  truncate-length="500"
                ></v-file-input>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Example: credentials.pickle
                </p>
              </v-col>

              <v-col v-if="'config' in fieldsToDisplay" cols="12" sm="12">
                <v-textarea
                  v-model="config"
                  :label="fieldsToDisplay.config"
                  outlined
                  dense
                ></v-textarea>
              </v-col>

              <v-col cols="12" sm="12">
                <v-switch
                  v-model="active"
                  :label="`Active: ${active.toString()}`"
                  outlined
                  dense
                ></v-switch>
              </v-col>

              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="savePartnerAccount"
                  >Enregistrer le PartnerAccount</v-btn
                >
                <v-btn class="me-3 mt-3" to="/admin/partner/accounts" outlined>
                  Abandonner
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
    <v-snackbar
      v-model="isSnackbarVisible"
      :timeout="2000"
      top
      :color="snackbarColor"
    >
      {{ snackbarText }}
    </v-snackbar>
    <v-dialog v-model="dialogConfirmVisible" max-width="800">
      <v-card>
        <v-card-title class="text-h5">
          {{ dialogConfirmTitle }}
        </v-card-title>
        <v-card-text>
          {{ dialogConfirmText }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="dialogConfirmCallback">
            Oui
          </v-btn>
          <v-btn color="green darken-1" @click.native="goToList">
            Abandonner
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref } from "@vue/composition-api";
import axios from "@axios";
import { required } from "@core/utils/validation";
import { runParallelAsyncs } from "@/utils/async";

export default {
  name: "PartnerAccountEdit",
  data() {
    return {
      required,
      selectedPartner: null,
      selectedPartnerCode: null,
      showConfigExample: false,
      fieldsToDisplay: [],
      fieldsToDisplayByPartner: {
        seedtag: { api_key: "api_key" },
        gam: {
          email: "Email",
          client_id: "Network code",
          app_id: "Position custom dimension Id",
          key_file_text:
            "Authenticated credentials / Azur Outlouk email app credentials",
          key_file_text_2: "Credentials / File conf",
          config: "JSON config",
        },
        viously: {
          key_file_text:
            'Credentials: csv ";" separated cols site_identifier;api_key;api_secret',
        },
        taboola: {
          identifier: "identifier",
          client_id: "client_id",
          client_secret: "client_secret",
        },
        avantis: { login: "login", password: "password" },
        pepsia: { client_id: "client_id", api_key: "api_key" },
        showheroes: { client_id: "client_id", api_key: "api_key" },
        impactify: { api_key: "api_key" },
        ogury: { api_key: "api_key" },
        teads: { email: "email", api_key: "api_key" },
        dailymotion: {
          client_id: "client_id",
          client_secret: "client_secret",
          login: "login",
          password: "password",
        },
        sublime: { api_key: "api_key", api_secret: "api_secret" },
        bliink: { login: "login", password: "password" },
        missena: { api_key: "api_key" },
        aax: { login: "login", password: "password" },
        inskin: {
          email: "email",
          key_file_text: "Azur Outlouk email app credentials (Json object)",
          key_file_text_2: "File conf (Json object)",
        },
        digiteka: { login: "login", password: "password" },
        outbrain: { login: "login", password: "password" },
        facebook: {
          app_id: "app_id",
          client_id: "user_id",
          client_secret: "client_secret",
          api_secret: "long token",
        },
        snapchat: {
          client_id: "client_id",
          client_secret: "client_secret",
          api_key: "api_key",
        },
        youtube: { client_id: "client_id", key_file_text: "key_file_text" },
        adpone: {
          api_key: "api_key",
        },
        richaudience: {
          api_key: "api_key",
        },
        browsi: { login: "login", password: "password" },
        pubstack: {
          email: "email",
          key_file_text: "Azur Outlouk email app credentials (Json object)",
          key_file_text_2: "File conf (Json object)",
        },
        actirise: {
          api_key:
            "Token (cf https://platform.actirise.com/profile/api-tokens)",
          key_file_text:
            "Liste des site identifier (closermag.fr, viepratique.fr). Un par ligne.",
        },
        kwords: {
          email: "email",
          key_file_text: "Azur Outlouk email app credentials (Json object)",
          key_file_text_2: "File conf (Json object)",
        },
      },
      mandatoryTextFields: [
        "id",
        "department_id",
        "partner_id",
        "name",
        "code",
      ],
      optionnalTextFields: [
        "identifier",
        "login",
        "email",
        "password",
        "client_id",
        "client_secret",
        "app_id",
        "app_secret",
        "api_key",
        "api_secret",
        "key_file_text",
        "key_file_text_2",
        "config",
      ],
      optionnalBoolFields: ["active"],
      inputFileFieldsComponentKeys: {
        key_file_binary: 0,
      },
      // fields
      id: null,
      department_id: null,
      partner_id: "",
      name: "",
      code: "",
      identifier: "",
      login: "",
      email: "",
      password: "",
      client_id: "",
      client_secret: "",
      app_id: "",
      app_secret: "",
      api_key: "",
      api_secret: "",
      key_file_text: "",
      key_file_binary: "",
      config: "",
      active: 0,
      // common elements
      snackbarColor: "info", // info, primary, success, warning, error
      snackbarText: "",
      dialogConfirmVisible: false,
      dialogConfirmTitle: "",
      dialogConfirmText: "",
      dialogConfirmCallbackFunction: null,
    };
  },
  async created() {
    await runParallelAsyncs({
      departmentsResponse: this.$store.dispatch("common/getDepartmentList"),
      partnersResponse: this.$store.dispatch("common/getPartnerList"),
    });

    if ("id" in this.$route.params && this.$route.params.id) {
      this.id = this.$route.params.id;
      const { data } = await axios.get(`/partner_accounts/${this.id}`);
      this.selectedPartnerCode = data.partner_code;
      this.setSelectedPartnerCode();
      for (const key in data) {
        if (key in this && key != "id") {
          this[key] = data[key];
        }
      }
    }
  },
  setup() {
    const isSnackbarVisible = ref(false);
    return {
      isSnackbarVisible,
    };
  },
  methods: {
    onChangeFileUploadKeyFileBinay(file) {
      this.key_file_binary = file;
    },
    setSelectedPartnerCode() {
      this.selectedPartner = this.partners.filter(
        (partner) => partner.code === this.selectedPartnerCode
      )[0];
      this.partner_id = this.selectedPartner.id;
      this.fieldsToDisplay =
        this.fieldsToDisplayByPartner[this.selectedPartnerCode];
      for (const field of this.optionnalTextFields) {
        this[field] = "";
      }
      for (const field in this.inputFileFieldsComponentKeys) {
        this.inputFileFieldsComponentKeys[field]++;
      }
    },
    async savePartnerAccount() {
      const isFormValid = this.$refs.FormPartnerAccount.validate();
      if (!isFormValid) return;

      let payload = {};
      for (const field of this.mandatoryTextFields) {
        payload[field] = this[field] !== "" ? this[field] : null;
      }
      for (const field of this.optionnalTextFields) {
        payload[field] = this[field] !== "" ? this[field] : null;
      }
      for (const field of this.optionnalBoolFields) {
        payload[field] = this[field];
      }
      const { status } = await axios.post(
        "/partner_accounts/",
        JSON.stringify(payload),
        { headers: { "Content-Type": "application/json" } }
      );
      if (status === 201) {
        this.showSnackBar("Partner account successfully saved.", "success");
        setTimeout(() => {
          this.goToList();
        }, 500);
      } else {
        this.showSnackBar(
          `An error occurred. Partner account failed to be saved.`,
          "error"
        );
      }
    },
    showSnackBar(text, color = "info") {
      this.snackbarColor = color; // info, primary, success, warning, error
      this.snackbarText = text;
      this.isSnackbarVisible = true;
    },
    dialogConfirmCallback() {
      this.dialogConfirmVisible = false;
      if (this.dialogConfirmCallbackFunction) {
        this.dialogConfirmCallbackFunction();
      }
      this.dialogConfirmCallbackFunction = null;
    },
    showDialogConfirm(title, text, callbackFunction) {
      this.dialogConfirmTitle = title;
      this.dialogConfirmText = text;
      this.dialogConfirmCallbackFunction = callbackFunction;
      this.dialogConfirmVisible = true;
    },
    dateToStr(date) {
      return date.toISOString().split("T")[0];
    },
    async goToList() {
      if (this.$route.name === "admin-partner-accounts") {
        this.$router.go();
        return;
      }
      this.$router.push({ name: "admin-partner-accounts" });
    },
  },
  computed: {
    departmentList() {
      const departments = this.$store.getters["common/getDepartmentList"].map(
        (dept) => ({
          id: dept.id,
          name: dept.name,
        })
      );
      departments.unshift({ id: "", name: "" });
      return departments;
    },
    partners() {
      return this.$store.getters["common/getPartnerList"];
    },
  },
  watch: {
    openDialogIsOpen(visible) {
      if (visible) {
        this.identifier = "";
        this.$refs.FormPartnerAccount.resetValidation();
      }
    },
  },
};
</script>
<style>
.field_key_file_text_2 textarea {
  height: 400px;
}
</style>
